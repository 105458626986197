export default class BusinessTableItem {
    businessId = 0;
    businessStatus = 0;
    businessParentId = 0;
    businessParentName = "";
    businessSortId = 0;
    businessName = "";
    businessRemark = "";
    createName = "";
    createTime = "";
    modifyName = "";
    modifyTime = "";
}
